
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { HistoryList } from '@/types/location'
import { timeFormat } from '../../utils/formatDate'
import { drawPolygon } from '@/utils/formatData'

@Component
export default class BraceletPlayback extends Vue {
  private date: any= ''
  private info = {
    workerId: this.workerId,
    startTime: '',
    endTime: ''
  }

  private historyList: Array<HistoryList> = []
  private lngLatList: Array<[number, number]> = []
  private workerList = []
  private markerDotList: Array<AMap.Marker> = []
  private projectLocationList: any = []
  private map: AMap.Map | any = null
  private marker: AMap.Marker | any = null
  private playSpeed = 3000
  private playSpeedTimes = 1
  private sliderVal = 0
  private start = false
  private pause = false
  private projectInfo = {
    longitude: '',
    latitude: ''
  }

  private pickerOptions = {
    shortcuts: [{
      text: '今天',
      onClick: (picker: any) => {
        const start = this.getCurrentTimeToHour()
        start.setHours(0)
        picker.$emit('pick', [start, this.getCurrentTimeToHour()])
      }
    }, {
      text: '昨天',
      onClick: (picker: any) => {
        const end = new Date(new Date().toLocaleDateString())
        const start = new Date()
        start.setTime(end.getTime() - 3600 * 1000 * 24)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '前天',
      onClick (picker: any) {
        const end = new Date(new Date().toLocaleDateString())
        const start = new Date()
        end.setTime(end.getTime() - 3600 * 1000 * 24)
        start.setTime(end.getTime() - 3600 * 1000 * 24)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '本周',
      onClick: (picker: any) => {
        const end = this.getCurrentTimeToHour()
        const start = new Date(new Date().toLocaleDateString())
        let day = start.getDay()
        day = day === 0 ? 6 : day - 1
        start.setTime(start.getTime() - 3600 * 1000 * 24 * day)
        picker.$emit('pick', [start, end])
      }
    }],
    onPick: (date: {maxDate: string; minDate: any}) => {
      this.date = date.minDate.getTime()
      if (date.maxDate) {
        this.date = ''
      }
    },
    // 限制最大一个月且不能大于当前时间
    disabledDate: (time: any) => {
      if (this.date !== '') {
        const one = 30 * 24 * 3600 * 1000
        const minTime: number = this.date - one
        const maxTime = this.date + one
        return time.getTime() > (Date.now() - 8.64e6) || (time.getTime() < minTime || time.getTime() > maxTime)
      } else {
        return time.getTime() > (Date.now())
      }
    }
  }

  get workerId () {
    return this.$route.params.workerId || ''
  }

  get projectId () {
    return this.$route.params.projectId || ''
  }

  created () {
    this.defaultDate()
    this.getDeviceList()
  }

  // 监听页面离开
  destroyed () {
    if (this.map) {
      // 停止运动
      this.marker && this.marker.stopMove()
      this.map.off('click', () => {
        //
      })
      this.map.destroy()
    }
  }

  getCurrentTimeToHour () {
    const date = new Date()
    date.setMinutes(0)
    date.setSeconds(0)
    return date
  }

  defaultDate () {
    // 默认最近一天
    const end = this.getCurrentTimeToHour()
    const start = end.getTime() - 3600 * 1000 * 24
    this.date = [timeFormat(start), timeFormat(end.getTime())]
    this.getData()
  }

  // 查询轨迹数据
  getData () {
    const info = this.date ? {
      workerId: this.info.workerId,
      startTime: this.date[0],
      endTime: this.date[1]
    } : this.info
    this.$axios.get(this.$apis.bracelet.track, info).then(res => {
      this.historyList = res || []
      this.lngLatList = []
      // 将轨迹需要经纬度push到数组
      res.map((item: {longitude: string; latitude: string}) => {
        this.lngLatList.push([Number(item.longitude), Number(item.latitude)])
      })
      if (res.length === 0) {
        this.$message({
          message: '未查询到数据',
          type: 'warning'
        })
      }
      if (this.projectLocationList.length > 0) {
        this.loadMap()
      } else {
        this.getProjectLocation()
      }
    })
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.projectInfo = res.projectInfo
      this.projectLocationList = res.projectLocation
      this.loadMap()
    })
  }

  // 获取工人列表
  getDeviceList () {
    this.$axios.get(this.$apis.worker.selectProjectWorkerByList, {
      projectId: this.projectId
    }).then((res) => {
      this.workerList = res.list || []
    })
  }

  // 渲染地图
  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: ['AMap.MoveAnimation']
    }).then(() => {
      const center: [number, number] = [Number(this.projectInfo.longitude), Number(this.projectInfo.latitude)]
      this.$nextTick(() => {
        const map = new AMap.Map('map', {
          center: center,
          zoom: 11
        })
        this.map = map
        // 画电子围栏
        drawPolygon(this.map, this.projectLocationList, null)
        if (this.lngLatList.length > 0) {
          this.marker = new AMap.Marker({
            map: this.map,
            position: this.lngLatList[0],
            icon: new AMap.Icon({
              // 图标尺寸
              size: new AMap.Size(36, 48),
              // 图标的取图地址
              image: require('@/assets/icon/bracelet/online.svg'),
              imageSize: new AMap.Size(36, 48)
            }),
            zIndex: 10000,
            offset: new AMap.Pixel(-20, -45)
          })
          const markerDot = new AMap.Marker({
            map: this.map,
            position: this.lngLatList[0],
            zIndex: 1,
            icon: new AMap.Icon({
              // 图标尺寸
              size: new AMap.Size(12, 12),
              // 图标的取图地址
              image: require('@/assets/icon/location/dot.svg'),
              imageSize: new AMap.Size(12, 12)
            }),
            offset: new AMap.Pixel(-8, -7),
            label: {
              offset: new AMap.Pixel(0, -20), // 设置文本标注偏移量
              content: '', // 设置文本标注内容
              direction: 'top' // 设置文本标注方位
            }
          })
          this.markerDotOpen(markerDot, 0)
          this.map.add(markerDot)
          this.map.setFitView([this.marker])
        }
      })
    })
  }

  // 加速
  speedUp () {
    if (this.playSpeedTimes < 8) {
      this.playSpeedTimes = this.playSpeedTimes * 2
    } else {
      this.playSpeedTimes = 1
    }
  }

  // 渲染动效
  loadMarker () {
    this.sliderVal = 0
    this.marker.moveAlong(this.lngLatList, {
      // 每一段的时长
      duration: (this.playSpeed / this.playSpeedTimes).toFixed(2),
      // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
      autoRotation: false
    }, (k: string) => {
      console.log(k, 111)
      return k
    }, false)
    // 创建跟速度信息展示框
    const carWindow = new AMap.InfoWindow({
      offset: new AMap.Pixel(0, -50),
      content: `<p class='date'>${this.historyList[0].collectTime}</p>`
    })
    // 动态改变信息窗
    const setVehicleSpeedInWidowns = (index: number) => {
      carWindow.setContent(`
        <p class='date'>${this.historyList[index].collectTime}</p>
      `)
    }
    // 6.速度框随车辆移动
    let schedule = 0
    // 绘制轨迹
    const polyline = new AMap.Polyline({
      map: this.map,
      showDir: true,
      strokeColor: '#3592FE', // 线颜色
      strokeWeight: 2 // 线宽
    })
    // 监听marker移动，改变时间条
    let lnglatBefore = [0, 0]
    this.marker.on('moving', (e: any) => {
      polyline.setPath(e.passedPath)
      const lastLocation = e.passedPath[e.passedPath.length - 1]
      carWindow.setPosition(lastLocation)
      const number = 100 / (this.historyList.length - 1)
      const lnglatDot: [number, number] = [e.pos.lng, e.pos.lat]
      if (e.progress >= 0.1) {
        this.map.setCenter(lnglatDot)
      }
      if (e.progress === 1) {
        schedule += number
        if (lnglatBefore[0] !== lnglatDot[0] && lnglatBefore[1] !== lnglatDot[1]) {
          const markerDot: AMap.Marker = new AMap.Marker({
            map: this.map,
            position: lnglatDot,
            zIndex: 1,
            icon: new AMap.Icon({
              // 图标尺寸
              size: new AMap.Size(12, 12),
              // 图标的取图地址
              image: require('@/assets/icon/location/dot.svg'),
              imageSize: new AMap.Size(12, 12)
            }),
            offset: new AMap.Pixel(-8, -7),
            label: {
              offset: new AMap.Pixel(0, -20), // 设置文本标注偏移量
              content: '', // 设置文本标注内容
              direction: 'top' // 设置文本标注方位
            }
          })
          this.markerDotOpen(markerDot, e.index + 1)
          this.map.add(markerDot)
          // 将打过的圆点放进数组方便删除
          this.markerDotList.push(markerDot)
          lnglatBefore = lnglatDot
        }
      } else if (e.progress) {
        this.sliderVal = number * e.progress + schedule
      }
      if (e.index === this.historyList.length - 2 && e.progress === 1) {
        this.sliderVal = 100
        this.endMove()
      }
      setVehicleSpeedInWidowns(e.index)
    })
    this.map.setFitView([polyline])
    // 7.打开速度框
    carWindow.open(this.map, this.marker.getPosition())
  }

  // 添加圆点点击弹出信息窗
  markerDotOpen (markerDot: any, index: number) {
    markerDot.on('click', (e: any) => {
      let labelShow = true
      this.map.getAllOverlays('marker').map((item: any) => {
        // 关闭所有信息窗
        if (item.getLabel().content !== '') {
          item.setLabel({
            content: ''
          })
          // 当点击同一个点时不添加信息窗
          if (item.hS === e.target.hS) {
            labelShow = false
          }
        }
      })
      // 添加信息窗
      if (labelShow) {
        markerDot.setLabel({
          content: `<p class='date'>${this.historyList[index].collectTime}</p><div class='amap-info-sharp'></div>`
        })
        markerDot.setzIndex(this.markerDotList.length + 1)
      }
    })
  }

  // 开始
  onStart () {
    if (this.pause) {
      this.pause = false
      this.marker.resumeMove()
    } else {
      this.start = true
      if (this.markerDotList.length > 0) {
        this.map.remove(this.markerDotList)
      }
      this.loadMarker()
    }
  }

  // 暂停
  pauseMove () {
    this.pause = true
    this.marker.pauseMove()
  }

  // 停止运动
  endMove () {
    this.start = false
    this.pause = false
    this.marker.stopMove()
  }

  // 查询
  onSearch () {
    this.start = true
    this.sliderVal = 0
    if (this.marker) {
      this.endMove()
      this.map.remove(this.marker)
    }
    this.getData()
  }
}
